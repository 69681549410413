<template>
  <svg class="icon-edit-row-table" width="24" height="24" viewBox="0 0 24 24" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M19.841 20.0006H13.7137C13.3639 20.0006 13.0801 19.7167 13.0801 19.367C13.0801 19.0172 13.3639 18.7334 13.7137 18.7334H19.841C20.1907 18.7334 20.4746 19.0172 20.4746 19.367C20.4746 19.7167 20.1907 20.0006 19.841 20.0006Z"
          fill="#BCC1C8"/>
    <mask id="mask0_2599_4068" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="4" y="4" width="15" height="16">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4 4H18.514V20H4V4Z" fill="white"/>
    </mask>
    <g mask="url(#mask0_2599_4068)">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M13.3858 5.70366L5.43206 15.6511C5.2876 15.8319 5.23438 16.065 5.2876 16.2889L5.8629 18.7261L8.43023 18.694C8.67438 18.6915 8.89994 18.5825 9.04946 18.3967C11.7672 14.9964 16.9483 8.51344 17.0953 8.32336C17.2338 8.09864 17.2879 7.781 17.2152 7.47519C17.1409 7.16177 16.9457 6.89566 16.6644 6.72586C16.6045 6.68446 15.181 5.57947 15.137 5.54483C14.6014 5.11568 13.82 5.19002 13.3858 5.70366ZM5.36288 20.0001C5.06974 20.0001 4.81461 19.799 4.74618 19.5126L4.0543 16.5804C3.91153 15.973 4.05345 15.3461 4.4429 14.8595L12.4009 4.90616C12.4042 4.90278 12.4068 4.89856 12.4102 4.89518C13.2828 3.85186 14.8609 3.69811 15.9253 4.55219C15.9676 4.58514 17.3809 5.68337 17.3809 5.68337C17.8945 5.98919 18.2958 6.53577 18.4479 7.18288C18.5991 7.82323 18.4893 8.48386 18.137 9.04227C18.1108 9.08366 18.088 9.11915 10.0388 19.1891C9.65105 19.6723 9.06984 19.9536 8.44553 19.9612L5.37133 20.0001H5.36288Z"
            fill="#BCC1C8"/>
    </g>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M16.0157 11.3368C15.8806 11.3368 15.7454 11.2937 15.6297 11.2059L11.0238 7.66789C10.7467 7.455 10.6944 7.05795 10.9073 6.77917C11.121 6.50207 11.518 6.45054 11.796 6.66343L16.4026 10.2006C16.6797 10.4135 16.7321 10.8114 16.5184 11.0893C16.3942 11.2515 16.2058 11.3368 16.0157 11.3368Z"
          fill="#BCC1C8"/>
  </svg>
</template>

<script>
export default {
  name: "IconEditRowTable"
}
</script>

<style lang="scss" scoped>
.icon-edit-row-table {
  &:hover path {
    fill: var(--green-main);
  }
}

</style>